import React, {Component} from 'react';
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
const MySwal = withReactContent(Swal)

class MessageSuccess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: '',
            title: ''
        }
    }

    componentDidMount() {
        const params = new URLSearchParams(this.props.location.search);
        this.setState({
            message: params.get("message"),
            title: params.get("title")
        })
    }

    render() {
        let message = this.state.message || '';
        let title = this.state.title || 'Success!';

        MySwal.fire({
            title: title,
            html: '<p>'+ message +'</p><br/><br/>',
            icon: 'success',
            showCloseButton: false,
            showCancelButton: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            backdrop: '#fff',
        })

        return (
            <React.Fragment>
            </React.Fragment>
        )
    }
}

export default MessageSuccess;
