import React, {Component} from "react"
import './background.css'

export class Background extends Component {
    render() {
        return (
            <React.Fragment>
                {(() => {
                    if (this.props.theme!=="colorful") {
                        return (
                            <div id="main-background" className={"theme-"+this.props.theme}></div>
                        )
                    } else {
                        return (
                            <div id="main-background" className={"theme-"+this.props.theme}>
                                <div id="stripes">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        )
                    }
                })()}
            </React.Fragment>
        )
    }

}