import React, {Component} from 'react';
import {Loader} from "semantic-ui-react";
import axios from "axios/index";
import './operoForm.css'
import {ErrorMessage} from "../components/messages/errorMessage";
import {Background} from "../components/background/background";
import {FormGenerator} from "../components/forms/formGenerator";
import { doPrepareApp } from '../services/opero';
//import {sendPay} from "../../../operopayments/src/services/opero";

const recaptchaRef = React.createRef();

class OperoForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: null,
            orgId: null,
            form: null,
            formEdit: null,
            formEditOk:true,
            relatedTo:null,
        }
    }

    async componentDidMount() {
        const host = window.location.host;
        if (host === "localhost:3000") {
            axios.interceptors.request.use(function (config) {
                config.url = "http://localhost:8084" + config.url;
                return config;
            })
        }

        const params = new URLSearchParams(this.props.location.search);
        await this.setState({ relatedTo: params.get('relatedto') || params.get('relatedTo')  });
        this.setState({loading: true, error: null});
        const queryParamsData = this.parseQueryParams();
        if (queryParamsData.orgId){
            try {
                let result = await doPrepareApp(queryParamsData);
                if (result.status === 200){
                    let d=result.data;
                    let fieldsOrdered= d.formFieldList.sort((a, b) => parseFloat(a.order) - parseFloat(b.order));
                    fieldsOrdered=this.getDefaultValues(fieldsOrdered,params)

                    this.setState({
                        loading: false,
                        error: null,
                        orgId: queryParamsData.orgId,
                        form: {
                            header: d.header,
                            subHeader: { text: d.subHeader.text },
                            captcha: d.captcha,
                            redirectUrl: d.redirectUrl,
                            fields: fieldsOrdered
                        },
                    });
                    this.setState({formEdit:this.state.form});
                    try{
                        document.title=this.state.form.header.text
                    }catch{
                        document.title="Form"
                    }

                }
                else{
                    throw Error(result.status);
                }
            } catch (err) {
                if (err.response) {
                    if (err.response.data.code) {
                        if (err.response.data.code === "NO_FORM" || err.response.data.code === "NO_ORG") {
                            err['message'] = "Form not Found";
                        }
                    }
                }else{
                    err['message']="Service not available"
                }
                this.setState({loading: false, error: err});
            }
        } else{
            this.setState({loading: false, error: { message: "Form not Found" }});
        }


    }

    parseQueryParams(){
        return {
            'orgId':this.props.match.params.orgId,
            'formName':this.props.match.params.formName,
            'relatedTo':this.state.relatedTo
        }
    }

    getDefaultValues(formFieldList,params){
        for (let i = 0; i < formFieldList.length; i++) {
            //By id
            if (params.get(formFieldList[i].id)){
                formFieldList[i].value=params.get(formFieldList[i].id)
            } else if (params.get("field"+(i+1))){
                formFieldList[i].value=params.get("field"+(i+1))
            }  else{
                formFieldList[i].value=""
            }
        }
        return formFieldList
    }

    getJsonForm() {
        let pretty;
        if (this.state.formEdit===this.state.form){
            pretty = JSON.stringify(this.state.formEdit, undefined, 2);
        }
        else{
            pretty = this.state.formEdit
        }
        return pretty
    }

    async handleUserInput (e) {
        const name = e.target.name;
        let value = e.target.value;
        await this.setState({[name]: value});

        if (name==="formEdit"){
            this.checkJson();
        }
        return true
    }

    checkJson(){
        try {
            JSON.parse(this.state.formEdit);
            this.setState({formEditOk: true});
        } catch (e) {
            this.setState({formEditOk: false});
        }
    }

    saveJSON(){
        try {
            const newJson = JSON.parse(this.state.formEdit);
            this.setState({form: newJson});
        } catch (e) {
            return false;
        }
    }

    render() {
        const {loading, error, orgId, form } = this.state;
        if (loading) {
            return <Loader active>Loading</Loader>
        } else if (error) {
            return <ErrorMessage message={error.message}/>
        } else if (!orgId) {
            return <ErrorMessage message="The form url is not valid"/>
        }

        let header = <div>
                        { form.header.imageUrl && <img className="logo" alt='' src={ form.header.imageUrl } width={form.header.imageWidth} height={form.header.imageHeight} />}
                        { form.header.text && <h2 className="m-4"> { form.header.text }</h2>}
        </div>;


        const subHeader = <div className="subtitle">{ form.subHeader.text && <h4 className="m-4" ><div dangerouslySetInnerHTML={{__html: form.subHeader.text}} /></h4>}</div>;
        const form1 = <div className="m-4"><FormGenerator fields={ form.fields } captcha={form.captcha} orgId={this.state.orgId} formName={this.props.match.params.formName} relatedTo={this.state.relatedTo} recaptchaRef={recaptchaRef} redirectUrl={this.state.form.redirectUrl} handleUserInput={this.handleUserInput} /></div>


        return (
            <React.Fragment>
                <Background />
                <div className="container-fluid">

                    <div className="row justify-content-center">
                        <div  className="col-12 col-md-6 col-lg-5 mt-4">
                            <div id="form-container">
                                { header }
                                { subHeader }
                                { form1 }
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default OperoForm;
