import axios from "axios";

export const doPrepareApp = (prepareAppRequest) => {
    return axios.post("/opero/v1/prepareApp", prepareAppRequest);
};

export const persistForm = (request) => {
    return axios.post("/opero/v1/persistForm", request);
};

export const uploadDocument = (request) => {
    return axios.post("/opero/v1/uploadDocument", request);
};

export const oauthCallback = async (code, state) => {
    return axios.post(`/oauth/v1/uiCallback?code=${code}&state=${state}`);
}

