import React from 'react';
import './App.css';
import {
    BrowserRouter as Router,
    Route
} from "react-router-dom";
import OperoForm from './views/operoForm'
import MessageSuccess from "./views/messageSuccess";
import MessageError from "./views/messageError";
import OauthCallback from "./views/oauthCallback";

function App() {
    return (
        <Router>
            <div className="App">
                <Route exact path="/" component={OperoForm}/>
                <Route exact path={"/:orgId/:formName?"} component={OperoForm}/>
                <Route exact path={"/oauth/v1/success/"} component={MessageSuccess}/>    {/* /message/success?message=test error */}
                <Route exact path={"/oauth/v1/error/"} component={MessageError}/>        {/* /message/success?message=test error */}
                <Route exact path={"/oauth/v1/callback"} component={OauthCallback}/>     {/* /oauth/v1/callback?code=xxxx&state=xxxx */}
            </div>
        </Router>
    );
}

export default App;