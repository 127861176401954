import React, {Component} from 'react';
import {persistForm, uploadDocument} from "../../services/opero";
import { Button, Form, TextArea, Select } from 'semantic-ui-react'
import ReCAPTCHA from "react-google-recaptcha";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
const MySwal = withReactContent(Swal)

export class FormGenerator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: null,
            captchaError: null,
            recaptchaToken: null,
            sendingForm: false
        }
    }
    componentDidMount() {
        this.setState({fields: this.props.fields});
        //console.log(this.props.redirectUrl)

    }

    handleUserSelect = async (event, data) => {
        const name = data.name;
        let value = data.value;
        this.handleUserInput ({target:{'name':name,'value':value}})
    };

    toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    async handleUserInput (e) {
        const name = e.target.name;
        let value = e.target.value;
        let fileBase64 = null;
        let fileName = null;

        if (e.target.type==="file"){
            e.persist();
            if (e.target.files[0]){
                fileBase64 = await this.toBase64(e.target.files[0]);
                fileName = e.target.files[0].name;
            }
        }

        if(e.target.type==="checkbox"){
            value=e.target.checked
        }

        let fields= this.state.fields
        for (let i = 0; i < fields.length; i++) {
            if (fields[i].id===name){
                if (e.target.type==="file") {
                    fields[i].fileBase64 = fileBase64;
                    fields[i].value=null;
                    fields[i].fileName = fileName;
                }
                else{
                    fields[i].value = value;
                }
                fields[i]=this.validateField(fields[i]);
            }
        }
        await this.setState({fields: fields});
        return true
    }


    validateField(field){
        field.error="";
        if(field.inputType==="email"){
            if(field.value) {
                let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})]?$)/i);
                if (!pattern.test(field.value)) {
                    field.error = "Please enter valid email address.";
                }
            }
        }

        if(field.inputType==="date"){
            if(field.value && field.value.match(/^\d{4}-\d{2}-\d{2}$/) === null) {
                    field.error = "Please enter valid date.";
            }
        }

        if(field.inputType==="captcha"){
            if (!field.value || field.value.length===0) {
                field.error = "This field is required";
            }
        }

        if(field.required){
            const min = field.min || 0;
            if(field.value || field.fileBase64){
                if(field.value && field.value.length<min){
                    field.error= min === 0 ? "This field is required" : "This field requires at least "+ min +" characters";
                }
            }
            else{
                field.error="This field is required";
            }
        }
        return field;
    }

    async onRecaptchaChange(value) {
        this.setState({ recaptchaToken: value })
        this.setState({captchaError: null});
    }

    async clearOnRecaptcha () {
        this.props.current.reset();
        await this.onRecaptchaChange(null)
    }

    async validate(){
        let fields= this.state.fields;
        let valid= true;
        this.setState({captchaError: null});

        for (let i = 0; i < fields.length; i++) {
            fields[i]=this.validateField(fields[i])
            if (fields[i].error){
                valid=false;
            }
        }
        if (this.props.captcha && !this.state.recaptchaToken){
            this.setState({captchaError: "This field is required"});
            valid=false;
        }
        await this.setState({fields: fields});
        return valid
    }

    downloadFile(filePath){
        var link=document.createElement('a');
        link.href = filePath;
        link.download = filePath.substr(filePath.lastIndexOf('/') + 1);
        link.click();
    }

    async submit(){

        if (await this.validate()){
            MySwal.fire({
                title: 'Please wait while processing',
                imageUrl: '/images/Spinner.gif',
                imageHeight: 80,
                imageAlt: 'Loading...',
                showCloseButton: false,
                showCancelButton: false,
                allowOutsideClick: false,
                showConfirmButton: false,
            })
            let fields= this.state.fields
            let fieldsToSend=[]
            let error = null;
            let serverMessage = null;
            for (let i = 0; i < fields.length; i++) {

                let value=fields[i].value

                if (fields[i].inputType.toLowerCase()==="checkbox"){
                    if (fields[i].value===true){
                        value="true"
                    } else {
                        value="false"
                    }
                }

                if (fields[i].inputType.toLowerCase()==="file upload" && !fields[i].value){
                    if (!fields[i].fileBase64){
                        //error="Error sending file";
                        console.log("without file")
                    } else {
                        try {
                            let result = await uploadDocument({
                                orgId: this.props.orgId,
                                'name': fields[i].fileName,
                                'data': fields[i].fileBase64.split(",")[1]
                            });
                            if (result.status === 200) {
                                value = result.data.id;
                            }
                        } catch (err) {
                            MySwal.fire({
                                title: 'Form sent!',
                                html: '<p>Now you can close this tab.</p><br/><br/>',
                                icon: 'success',
                                showCloseButton: false,
                                showCancelButton: false,
                                allowOutsideClick: false,
                                showConfirmButton: false,
                                backdrop: '#fff',
                                willClose: () => {
                                    window.close();
                                }
                            })
                        }

                    }
                }
                fieldsToSend.push({
                    'id':fields[i].id,
                    'value':value,
                })
            }

            try {
                if (!error) {
                    let request = {
                        'orgId': this.props.orgId,
                        'formName': this.props.formName,
                        'fields': fieldsToSend,
                        'relatedTo': this.props.relatedTo,
                        'recaptchaToken': this.state.recaptchaToken,
                    }

                    this.setState({sendingForm: true});
                    let result = await persistForm(request);
                    if (result.status === 200) {

                        let message='<p>Now you can close this tab.</p><br/><br/>'
                        try {
                            //let response = JSON.parse(result.data)
                            let response = result.data
                            console.log(response)
                            if (response.message.length>1){
                                message = "<p>" + response.message + "</p>"
                            }

                            if (response.googleDocViewLink && response.completionAction==="Document Action: Goto Google Doc"){
                                message = message+"<a class=\"ui button green mt-2 mb-4\" href='"+response.googleDocViewLink+"' target='_blank'>View Document</a>"
                            } else if (response.eSignLink && response.completionAction==="Document Action: eSign" ){
                                message = message+"<a class=\"ui button green mt-2 mb-4\" href='"+response.eSignLink+"' target='_blank'>Sign Document</a>"
                            }
                            else if (response.file.fileDownloadLink && response.completionAction==="Document Action: Download File" ){
                                message = message+"<button class=\"ui button green mt-2 mb-4\" onClick='window.location.assign(\""+response.file.fileDownloadLink+"\");' >Download File</button>"
                            }
                        } catch (err){}

                        if (this.props.redirectUrl){
                            window.location.href = this.props.redirectUrl;
                        }
                        else{
                            MySwal.fire({
                                title: message,
                                icon: 'success',
                                showCloseButton: false,
                                showCancelButton: false,
                                allowOutsideClick: false,
                                showConfirmButton: false,
                                backdrop: '#fff',
                                willClose: () => {
                                    window.close();
                                }
                            })
                        }

                    } else {
                        error = "At this time it is not possible to process your information, please try again later";
                    }
                }
            } catch (err) {
                error = err.response.data.message || " ";
                serverMessage = err.response.data.serverMessage;
                try {
                    let errors=JSON.parse(error)
                    error=errors.message
                } catch (err){}
                /*let errors_sf = ""
                try {
                    for (let i = 0; i < errors.length; i++) {
                        if (errors[i].errorCode === "ERR_SUBMIT_FORM") {
                            errors_sf += errors[i].message + "</br>"
                        }
                    }
                }catch (err){}
                if (errors_sf.length>0){
                    error = errors_sf
                }*/
            }
            this.setState({sendingForm: false});
            if (error){
                MySwal.fire({
                    title: 'The form could not be submitted',
                    html: error,
                    icon: 'warning',
                    showCloseButton: true,
                    showCancelButton: false,
                    allowOutsideClick: false,
                    showConfirmButton: (serverMessage),
                    confirmButtonText: 'Show more info',
                    confirmButtonColor: '#5d5c60',
                    preConfirm: () => {
                        Swal.showValidationMessage(
                            `Error: ${serverMessage}`
                        )
                    },
                })
            }
        }
    }

    render() {

        let fieldsOrdered= this.props.fields

        let fields = fieldsOrdered.map(f => (
            <Form.Field key={f.order} >
                {f.inputType.toLowerCase() !== "checkbox" ?
                    <div>
                        <label>{f.label} {f.required && <span className="required">*</span>}</label>
                        <br/><small>{f.hintText}</small>
                    </div>
                    : <div className="ui checkbox">
                        <input type="checkbox" disabled={f.disabled} tabIndex="0" name={f.id}  defaultChecked={f.value==="checked"} onChange={(event) => this.handleUserInput(event)} /><label>{f.label}</label></div>
                }
                { f.inputType.toLowerCase()==="text" && <input type="text" placeholder={f.placeholder} value={f.value} name={f.id} disabled={f.disabled} onChange={(event) => this.handleUserInput(event)} /> }
                { f.inputType.toLowerCase()==="date" && <input type="date" disabled={f.disabled}  value={f.value} name={f.id} onChange={(event) => this.handleUserInput(event)} /> }
                { f.inputType.toLowerCase()==="email" && <input type="email" placeholder={f.placeholder} value={f.value} disabled={f.disabled}  name={f.id} onChange={(event) => this.handleUserInput(event)}  /> }
                { f.inputType.toLowerCase()==="file upload" && <input type="file" placeholder={f.placeholder} disabled={f.disabled} name={f.id} onChange={(event) => this.handleUserInput(event)}  /> }
                { f.inputType.toLowerCase()==="text area" && <TextArea placeholder={f.placeholder} disabled={f.disabled} rows="3" value={ f.value ? f.value : ""}  name={f.id} onChange={(event) => this.handleUserInput(event)}  /> }
                { f.inputType.toLowerCase()==="picklist" && <Select placeholder={f.placeholder} defaultValue={f.value} options={f.options} name={f.id} onChange={this.handleUserSelect}  /> }
                <div className="text-danger">{ f.error}</div>
            </Form.Field>
        ));

        return (
            <Form>
                { fields }
                { ( this.props.captcha && !this.state.sendingForm ) && <ReCAPTCHA  ref={this.props.recaptchaRef}  sitekey="6LeQ-lMdAAAAABfCB7qQsosCAzfxKeXBeEKjGKGC" onChange={(event) => this.onRecaptchaChange(event) } /> }
                { this.props.captcha  && <div className="text-danger">{ this.state.captchaError }</div> }
                <Button type='button' className="mt-2 mb-4" onClick={() => this.submit()}>Submit</Button>
            </Form>
        )
    }

}